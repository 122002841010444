import React, { Component } from 'react'
import Slider from 'react-slick'
import ImageWrap from 'components/ImageWrap'
import NextArrow from './nextArrow'
import PrevArrow from './prevArrow'

const Slide = ({ slide }) => {
  if (slide.images) {
    return (
      <div className="slide-wrap">
        <div
          className={
            slide.images.length > 1
              ? 'image-layout layout-double'
              : 'image-layout layout-single'
          }
        >
          {slide.images.map((image, i) => {
            return (
              <div key={i} className="img-wrapper-container">
                <div className="img-wrapper-proportion no-padding">
                  <ImageWrap image={image} />
                </div>
              </div>
            )
          })}
        </div>
        {slide.caption && <div className="wrap caption">{slide.caption}</div>}
      </div>
    )
  } else {
    return <div />
  }
}

class PaddedSlideshow extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      centerMode: true,
      speed: 777,
      focusOnSelect: true,
      slidesToShow: 1,
      infinite: true,
      centerPadding: '0px',
    }

    return (
      <div className="mixed-slideshow-container has-arrows constant-height-mixed">
        {this.props.slides && (
          <Slider {...settings} className="mixed-slideshow">
            {this.props.slides.map((slide, i) => (
              <Slide key={i} slide={slide} />
            ))}
          </Slider>
        )}
      </div>
    )
  }
}

export default PaddedSlideshow
