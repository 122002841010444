import React from 'react'
import LinkWrap from 'components/LinkWrap'
import { trFunction } from 'utils/functions'

const Pagination = (props) => {
  const locale = props.data.site.siteMetadata.locale
  const translations = props.data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const items = props.list
  const currentEntry = props.item
  const label = props.label

  let prevLabel = ''
  let nextLabel = ''
  let index = ''

  for (let i = 0; i < items.length; i++) {
    if (items[i].node.id === currentEntry.id) {
      index = i
    }
  }

  let nextIndex = index + 1
  if (nextIndex === items.length) {
    nextIndex = 0
  }
  let nextEntry = items[nextIndex]
  nextLabel = tr('NEXT') + tr('SPACE') + label
  if (label === 'title') nextLabel = nextEntry.node.title

  let prevIndex = index - 1
  if (prevIndex < 0) {
    prevIndex = items.length - 1
  }
  let prevEntry = items[prevIndex]
  prevLabel = tr('PREVIOUS') + tr('SPACE') + label
  if (label === 'title') prevLabel = prevEntry.node.title

  return (
    <section className="pagination-single">
      <div className="layout-2">
        <div>
          <ul>
            <li>
              <LinkWrap to={`/${props.slug}/${prevEntry.node.slug}`}>
                « {prevLabel}
              </LinkWrap>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <LinkWrap to={`/${props.slug}/${nextEntry.node.slug}`}>
              {nextLabel} »
            </LinkWrap>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Pagination
