import React, { Component } from 'react'

class NextArrow extends Component {
  render() {
    return (
      <div className="arrow arrow-right" onClick={this.props.onClick}>
        <div className="arrow-int" />
      </div>
    )
  }
}

export default NextArrow
