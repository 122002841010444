import React, { Component } from 'react'
import Layout from 'components/layout'
import Pagination from 'components/pagination'
import PaddedSlideshow from 'components/slideshows/paddedSlideshow'
import Title from 'components/title'
import { dateFormatted, trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

class News extends Component {
  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const allNews = this.props.data.allNews.edges
    const thisNews = this.props.data.thisNews
    const thisPage = this.props.data.thisPage
    return (
      <Layout>
        <div id="content">
          <Title
            subtitle={thisPage.title}
            subtitleLink="/news"
            title={thisNews.title}
          />
          {thisNews.slides && <PaddedSlideshow slides={thisNews.slides} />}
          <section className="page-content wrap">
            <div
              className="small-caps date header-date"
              style={{ textAlign: 'center' }}
            >
              {dateFormatted(thisNews.datePosted, locale)}
            </div>
            <div className="wrap-text-large centered strip-p single-commons">
              {thisNews.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: thisNews.content.childMarkdownRemark.html,
                  }}
                />
              )}
            </div>
            <Pagination
              slug={thisPage.slug}
              list={allNews}
              item={thisNews}
              label={tr('ARTICLE')}
              data={this.props.data}
            />
          </section>
        </div>
      </Layout>
    )
  }
}
export default News

export const newsQuery = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    allNews: allContentfulNews(sort: { fields: [datePosted], order: DESC }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    thisNews: contentfulNews(slug: { eq: $slug }) {
      id
      title
      slug
      datePosted
      slides {
        id
        images {
          title
          ...GatsbyImage
        }
        caption
        highlightPoints {
          title
          caption
          triggerPositionLeft
          triggerPositionTop
          popupAlign
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        ...GatsbyImage
      }
    }
    thisPage: contentfulPages(slug: { eq: "news" }) {
      title
      slug
    }
  }
`
